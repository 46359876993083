import { LazyDialogAuthLoginRegister } from '#components';

export default defineNuxtRouteMiddleware((to) => {
  const nuxtApp = useNuxtApp();

  if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
    return;
  }

  const authStore = useAuthStore();

  if (authStore.loggedIn) {
    return;
  }

  if (import.meta.client) {
    const { notify, dialog } = useQuasar();

    notify({
      type: 'negative',
      message: 'You must be logged in to continue.',
      actions: [
        {
          label: 'Login',
          color: 'white',
          handler: () =>
            dialog({
              component: LazyDialogAuthLoginRegister,
              componentProps: {
                redirect: to.fullPath,
              },
            }),
        },
      ],
    });
  }

  return abortNavigation(
    createError({
      statusCode: 401,
      message: 'Authentication Required',
    }),
  );
});
